import Vue from "vue";
import Vuex from "vuex";
import Buefy from "buefy";
import { mapState } from "vuex";
import FeaturesCarousel from "../vue/components/LottieComponents/FeaturesCarousel.vue";

Vue.use(Buefy);
Vue.use(Vuex);

// import TransitionHero from "./components/HeroComponents/TransitionHero.vue";
// import AdvertisementHero from "./components/HeroComponents/AdvertisementHero.vue";
// import CategoryCarousel from "./components/CategoryCarousel/CategoryCarousel.vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

(async () => {
	// We recommend to call `load` at application startup.
	const fp = await FingerprintJS.load();

	// The FingerprintJS agent is ready.
	// Get a visitor identifier when you'd like to.
	const result = await fp.get();

	// This is the visitor identifier:
	const visitorId = result.visitorId;
	console.log(visitorId);
})();

new Vue({
   el: '#headingCarousel',
   data() {
      return {
      }
   },
   mounted(){
      /*
      Ensure images used by carousel are pre-loaded.
      */
      let preloadedImages = [
         {src: "https://images.pexels.com/photos/3900424/pexels-photo-3900424.jpeg?cs=srgb&fm=jpg"},
         {src: "https://images.pexels.com/photos/1734436/pexels-photo-1734436.jpeg?cs=srgbfm=jpg&w=5653&h=3769"}
      ];

      preloadedImages.forEach((item,index) => {
         item.img = new Image();
         item.img.src = `${item.src}`;
      })
      this.$nextTick(() => {
         /*
            Ensure all carousel items are the same height by setting the minimum
            height of all carousel-item contents to the height of the slide with
            the most content.
         */
         // Get height of the tallest carousel item (based on content)
         let carouselItems = this.$el.querySelectorAll('div.carouselItem');
         let tallestContentElementHeight = 0; // unit used: px
         carouselItems.forEach((slideElement, index) => {
            // Get content element that is inside carousel slide element
            const contentElement = slideElement.children[0].children[0];
            // Store if carousel has made current carousel item display:none
            const isDisplayed = slideElement.style.getPropertyValue('display') !== 'none'
            // Display current carousel item
            slideElement.style.setProperty('display', '');
            const contentElementHeight = contentElement.getBoundingClientRect().height;
            if (contentElementHeight > tallestContentElementHeight) {
               tallestContentElementHeight = contentElementHeight;
            }
            console.debug(contentElementHeight);
            // Set carousel item to display none if previously not visible
            if(!isDisplayed){slideElement.style.setProperty('display','none')}
         })
         console.debug(tallestContentElementHeight);

         // Set height of all carousel item content to tallestCarousel
         carouselItems.forEach((slideElement, index) => {
            let contentElement = slideElement.children[0].children[0];
            contentElement.style.setProperty('min-height', tallestContentElementHeight + 'px');
         })
      })
   }
});
new Vue({
   el: '#featuresCarousel',
   components: {FeaturesCarousel},
   data() {
      return {
      }
   }
})
