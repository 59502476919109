<template>
	<b-carousel
		class="box p-1-mobile"
		:pause-info="false"
		:autoplay="true"
		:interval="5500"
		:icon-pack="'fas'"
		:icon-size="'is-medium'"
		:icon-prev="'arrow-left'"
		:icon-next="'arrow-right'">
		<b-carousel-item class="p-3-desktop p-1-mobile">
			<div class="p-4-desktop p-2-mobile">
				<section class="columns is-vcentered is-white is-bold">
					<div
						class="column is-three-fifths px-6-desktop has-text-centered-mobile">
						<div class="p-5-desktop p-2-tablet p-2-mobile">
							<p class="is-size-4 is-size-6-mobile">We offer...</p>
							<p
								class="is-size-1 is-size-3-mobile has-text-weight-bold is-uppercase pb-2-desktop pt-1 pb-1">
								One Account
							</p>
							<p class="is-size-5 is-size-6-mobile">
								We've simplified things! Create one account to get
								access to everything. Choose to continue shopping, or to
								add products to your new the store.
							</p>
						</div>
					</div>
					<div class="column is-two-fifths px-6-desktop">
						<div class="m-4-desktop m-2-tablet">
							<lottie-player
								autoplay
								loop
								mode="normal"
								src="/image/lottie/one-account.json"
								style="width: 100%"></lottie-player>
						</div>
					</div>
				</section>
			</div>
		</b-carousel-item>
		<b-carousel-item class="p-3-desktop p-1-mobile">
			<div class="p-4-desktop p-2-mobile">
				<section class="columns is-vcentered is-white is-bold">
					<div
						class="column is-three-fifths px-6-desktop has-text-centered-mobile">
						<div class="p-5-desktop p-2-tablet p-2-mobile">
							<p class="is-size-4 is-size-6-mobile">We offer...</p>
							<p
								class="is-size-1 is-size-3-mobile has-text-weight-bold is-uppercase pb-2-desktop pt-1 pb-1">
								Communication
							</p>
							<p class="is-size-5 is-size-6-mobile">
								Communication is necessary for any relationship. Jamart
								messages shoppers and and sellers to ensure your product
								reaches you.
							</p>
						</div>
					</div>
					<div class="column is-two-fifths px-6-desktop">
						<div class="m-4-desktop m-2-tablet">
							<lottie-player
								autoplay
								loop
								mode="normal"
								src="/image/lottie/communication.json"
								style="width: 100%"></lottie-player>
						</div>
					</div>
				</section>
			</div>
		</b-carousel-item>
		<b-carousel-item class="p-3-desktop p-1-mobile">
			<div class="p-4-desktop p-2-mobile">
				<section class="columns is-vcentered is-white is-bold">
					<div
						class="column is-three-fifths px-6-desktop has-text-centered-mobile">
						<div class="p-5-desktop p-2-tablet p-2-mobile">
							<p class="is-size-4 is-size-6-mobile">We offer...</p>
							<p
								class="is-size-1 is-size-3-mobile has-text-weight-bold is-uppercase pb-2-desktop pt-1 pb-1">
								Social Media
							</p>
							<p class="is-size-5 is-size-6-mobile">
								Don't want to abandon your social media audience? Set
								your store to post to your social media whenever you
								post a new product.
							</p>
						</div>
					</div>
					<div class="column is-two-fifths px-6-desktop">
						<div class="m-4-desktop m-2-tablet">
							<lottie-player
								autoplay
								loop
								mode="normal"
								src="/image/lottie/social-media.json"
								style="width: 100%"></lottie-player>
						</div>
					</div>
				</section>
			</div>
		</b-carousel-item>
		<b-carousel-item class="p-3-desktop p-1-mobile">
			<div class="p-4-desktop p-2-mobile">
				<section class="columns is-vcentered is-white is-bold">
					<div
						class="column is-three-fifths px-6-desktop has-text-centered-mobile">
						<div class="p-5-desktop p-2-tablet p-2-mobile">
							<p class="is-size-4 is-size-6-mobile">We offer...</p>
							<p
								class="is-size-1 is-size-3-mobile has-text-weight-bold is-uppercase pb-2-desktop pt-1 pb-1">
								Seller Services
							</p>
							<p class="is-size-5 is-size-6-mobile">
								Jamart makes it easy for you as a seller to accept
								payments in your store in your wallet. Depositing the
								money into your bank account is also easy!
							</p>
						</div>
					</div>
					<div class="column is-two-fifths px-6-desktop">
						<div class="m-4-desktop m-2-tablet">
							<lottie-player
								autoplay
								loop
								mode="normal"
								src="/image/lottie/seller-services.json"
								style="width: 100%"></lottie-player>
						</div>
					</div>
				</section>
			</div>
		</b-carousel-item>
	</b-carousel>
</template>
<style scoped></style>
<script>
	import Vue from "vue";
	import Buefy from "buefy";
	import "@lottiefiles/lottie-player";

	Vue.use(Buefy);

	export default {
		data() {
			return {};
		},
		computed: {},
		methods: {},
	};
</script>
